import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = ({ que, ans, type }) => {
  // const [isOpen, setIsOpen] = useState(false);
  let answerSec = <div>{ans}</div>;
  if (type === "div-list") {
    answerSec = (
      <>
        {ans.map((a) => (
          <div>{a}</div>
        ))}
      </>
    );
  }
  if (type === "list") {
    answerSec = (
      <>
        <ul>
          {ans.map((a) => (
            <li>{a}</li>
          ))}
        </ul>
      </>
    );
  }
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{que}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{answerSec}</AccordionItemPanel>
      </AccordionItem>
      {/* <div className="qna">
        <div className="que" onClick={() => setIsOpen(!isOpen)}>
          {que} {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
        <div className={`ans ${isOpen ? "open" : ""}`}>{answerSec}</div>
      </div> */}
    </>
  );
};

export default Faq;
