import {
  btnAppleBlackBg,
  btnAppleWhiteBg,
  btnGoogleBlackBg,
  downloadBackgroundBlack,
  headerSideImages,
  howToUseStepsBlack,
  howToUseStepsBlackMobile,
  priceBackground,
  whiteCircleCheck,
} from "assestes";
import MenuIcon from "@material-ui/icons/Menu";
import "./App.css";
import { useState } from "react";
import Faq from "faq";
import { Accordion } from "react-accessible-accordion";

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="App">
      <div className="header-wrapper">
        <div className="header">
          <div className="logo">Yanida</div>
          <div className="c-menu">
            <span className="c-menu-wrapper">
              <div className="menu-items">
                <div
                  className="menu-item"
                  onClick={() =>
                    document.getElementById("home-section").scrollIntoView()
                  }
                >
                  Home
                </div>
                <div
                  className="menu-item"
                  onClick={() =>
                    document
                      .getElementById("how-it-work-section")
                      .scrollIntoView()
                  }
                >
                  How it works
                </div>
                <div
                  className="menu-item"
                  onClick={() =>
                    document.getElementById("pricing-section").scrollIntoView()
                  }
                >
                  Pricing
                </div>
                <div
                  className="menu-item"
                  onClick={() => {
                    window.location.href = "mailto: info@yanida.co.uk";
                  }}
                >
                  Contact us
                </div>
              </div>
            </span>
          </div>
          <div className="r-menu">
            <span className="r-menu-wrapper">
              <div className="menu-items">
                <div
                  className="menu-item faq"
                  onClick={() =>
                    document.getElementById("faq-section").scrollIntoView()
                  }
                >
                  FAQs
                </div>
                <div className="menu-item">
                  <MenuIcon
                    className="mobile-only"
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  />
                  <div
                    className="btn download"
                    onClick={() => {
                      document
                        .getElementById("download-section")
                        .scrollIntoView();
                    }}
                  >
                    Download
                  </div>
                </div>
              </div>
            </span>
          </div>
          {menuOpen && (
            <div className={`mobile-menu ` + (menuOpen ? "active" : "")}>
              <div
                className="menu-item"
                onClick={() => {
                  document.getElementById("home-section").scrollIntoView();
                  setMenuOpen(false);
                }}
              >
                Home
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  document
                    .getElementById("how-it-work-section")
                    .scrollIntoView();
                  setMenuOpen(false);
                }}
              >
                How it works
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  document.getElementById("pricing-section").scrollIntoView();
                  setMenuOpen(false);
                }}
              >
                Pricing
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  window.location.href = "mailto: info@yanida.co.uk";
                }}
              >
                Contact us
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  document.getElementById("faq-section").scrollIntoView();
                  setMenuOpen(false);
                }}
              >
                FAQs
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  document.getElementById("download-section").scrollIntoView();
                  setMenuOpen(false);
                }}
              >
                Download
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="landing">
        <div className="home-section" id="home-section">
          <div className="left">
            <div className="sub-title-top"></div>
            <div className="title">
              Book an <span className="red">early</span> driving test within{" "}
              <span className="red">minutes</span>
            </div>
            <div className="sub-title-bottom">
              Fast forward to your test date. Skip the long queues ahead of you.
              Download our app and pass your driving test.
            </div>
            <div className="header-download-section">
              <div className="header-download-buttons">
                <img
                  className="btn"
                  src={btnAppleBlackBg}
                  alt="btnAppleBlackBg"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/yanida/id6446505688",
                      "_blank"
                    )
                  }
                />
                <img
                  className="btn"
                  src={btnGoogleBlackBg}
                  alt="btnGoogleBlackBg"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=uk.co.yanida",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="right">
            <img src={headerSideImages} alt="headerSideImages" />
          </div>
        </div>
        <div className="how-it-work-section" id="how-it-work-section">
          <div className="title">FASTEST TEST SERVICE IN THE COUNTRY</div>
          <div className="steps">
            <div className="step-wrapper">
              <div className="step-title">3 Simple Steps</div>
              <div className="step-details">
                We promise to process an immediate refund if you are unhappy
                with our service, as long as it is not within 5 days of your
                test
              </div>
            </div>
            <img
              src={
                window.innerWidth > 600
                  ? howToUseStepsBlack
                  : howToUseStepsBlackMobile
              }
              alt="howToUseSteps"
            />
          </div>
        </div>
        <div
          className="download-section"
          id="download-section"
          style={{ backgroundImage: `url(${downloadBackgroundBlack})` }}
        >
          <div className="sub-title">Wait no longer.</div>
          <div className="title">Download our app and start today!</div>
          <div className="download-btn-wrappers">
            <div className="btn-icon">
              <img
                src={btnAppleWhiteBg}
                alt="btnApple"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/yanida/id6446505688",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="btn-icon">
              <img
                src={btnGoogleBlackBg}
                alt="btnGooglePlay"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=uk.co.yanida",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div
          className="price-card-wrapper"
          style={{ backgroundImage: `url(${priceBackground})` }}
        >
          <div className="title">Any Centre in the UK, for just:</div>
          <div className="price">£199.99</div>
          <div className="price-sub-title">cancel anytime</div>
          <div className="price-checks">
            <div className="check-item-wrapper">
              <div className="check-item">
                <div className="icon">
                  <img src={whiteCircleCheck} alt="whiteCircleCheck" />
                </div>
                <div className="text">No existing test required</div>
              </div>
            </div>
            <div className="check-item-wrapper">
              <div className="check-item">
                <div className="icon">
                  <img src={whiteCircleCheck} alt="whiteCircleCheck" />
                </div>
                <div className="text">Priority Test Offers</div>
              </div>
            </div>
            <div className="check-item-wrapper">
              <div className="check-item">
                <div className="icon">
                  <img src={whiteCircleCheck} alt="whiteCircleCheck" />
                </div>
                <div className="text">Unlimited Test Centres</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pricing-section" id="pricing-section">
          <div className="title">Pricing</div>
          <div className="card-wrapper">
            <div className="card">
              <div className="card-item">
                <div className="title-wrapper">
                  <div className="title">Standard</div>
                </div>
                <div className="note-items">
                  <div className="note-item">Existing booking needed</div>
                  <div className="note-item">Refund 5 days before test</div>
                  <div className="note-item">Guaranteed Test</div>
                </div>
                <div className="price">£49.99</div>
              </div>
            </div>
            <div className="card">
              <div className="card-item">
                <div className="title-wrapper">
                  <div className="title">Premium</div>
                </div>
                <div className="note-items">
                  <div className="note-item">No existing test required</div>
                  <div className="note-item">Faster turnaround</div>
                  <div className="note-item">More test options</div>
                </div>
                <div className="price">£119.99</div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="faq-section" id="faq-section">
          <div className="title">FAQS</div>
          <Accordion>
            {[
              {
                que: "What is Yanida?",
                ans: "Yanida is the ultimate solution for getting on the road faster! We're a fast track service that helps you find early driving tests without the hassle of constantly checking the DVSA website.",
              },
              {
                que: "Why should I use Yanida?",
                ans: "Our team works tirelessly to find you the earliest available practical test that meets your preferences. With Yanida, you'll enjoy a range of exciting features that make booking your test a breeze. Plus, we offer free cancellation with a full refund (T&Cs apply)!",
              },
              {
                que: "What is auto-booking and how does it work?",
                ans: "Auto-booking is an amazing feature that automatically books your practical test in accordance to your specifications. To enable this, you simply need to go on your app settings and allow access.",
              },
              {
                que: "What features does Yanida have?",
                ans: (
                  <>
                    <div>
                      Yanida comes packed with exciting features that put you in
                      control of your driving test:
                    </div>
                    <ui>
                      <li>Automatic booking</li>
                      <li>Choose up to 5 test centres of your preference</li>
                      <li>
                        Flexible in terms of changing already booked dates to a
                        different one (T&Cs apply)
                      </li>
                      <li>Free cancellation with a full refund (T&Cs apply)</li>
                      <li>
                        How will I know if a suitable test is found for me?
                      </li>
                    </ui>
                    <div>
                      Yanida's app notifies you within seconds when a suitable
                      test is found that matches your requirements. From there,
                      you'll have the option to accept or decline the slot. To
                      enable this feature, simply go on your app settings and
                      allow access.
                    </div>
                  </>
                ),
              },
              {
                que: "How likely is it that I will get an early test date?",
                ans: "We aim to find you the earliest available practical test within your desired time frame.",
              },
              {
                que: "Do I need a test in order to use Yanida?",
                ans: "No - you won't need a test to use Yanida, we will book a test in a test centre that has an available slot. We will then look for an available slot at your preferred centre.",
              },
              {
                que: "If I have a weekday test booked, can I swap it for a weekend date?",
                ans: "As a Standard user, this option is not available. However, as a Premium user, you have the flexibility to choose from both weekday and weekend test dates.",
              },
              {
                que: "I’ve lost my theory test certificate, what do I need to do?",
                ans: "If you've lost your theory test certificate, you need to contact DVSA as soon as possible. You can reach them at 0300 200 1122 or by emailing them at customercare@pearson.com.",
              },
              {
                que: "I passed my theory test 1 year ago, can I still take a practical test?",
                ans: "Yes, your theory test certificate lasts for 2 years from the date you passed. However, if the 2 year duration is over, you will need to retake your theory test.",
              },
              {
                que: "Do I need to pay again if I fail?",
                ans: "Yes, unfortunately you will need to pay again if you fail your test.",
              },
              {
                que: "If I decide to terminate my subscription, will I receive a refund?",
                ans: [
                  "Absolutely! Our policy states that if you decide to discontinue using our services, you'll receive a complete refund subject to our terms and conditions.",
                  "We hope you're as excited as we are about Yanida's amazing features and fast-track service! With our help, you'll be hitting the road sooner and with less stress. So why wait? Sign up today and take the first step towards getting your driver's license with Yanida.",
                ],
                type: "div-list",
              },
            ].map((f, i) => (
              <Faq que={f.que} ans={f.ans} type={f.type} key={i} />
            ))}
          </Accordion>
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="footer-section">
          <div className="section-container">
            <div className="text-part">
              <div className="text-wrap">
                <div className="item explore">
                  <p className="item-title">EXPLORE</p>
                  <p
                    className="item-text"
                    onClick={() =>
                      document.getElementById("home-section").scrollIntoView()
                    }
                  >
                    Home
                  </p>
                  <p
                    className="item-text"
                    onClick={() =>
                      document
                        .getElementById("how-it-work-section")
                        .scrollIntoView()
                    }
                  >
                    How it works
                  </p>
                  <p
                    className="item-text"
                    onClick={() =>
                      document
                        .getElementById("pricing-section")
                        .scrollIntoView()
                    }
                  >
                    Pricing
                  </p>
                  <p
                    className="item-text"
                    onClick={() =>
                      document.getElementById("faq-section").scrollIntoView()
                    }
                  >
                    FAQs
                  </p>
                </div>
                <div className="item legal">
                  <p className="item-title">LEGAL</p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/document/terms-and-conditions/fd43245d-3109-4dba-a71c-6f75a0bfed7b",
                        "_blank"
                      );
                    }}
                  >
                    Terms and Conditions
                  </p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open(
                        "https://app.termly.io/document/privacy-policy/bc8c1c2a-bfec-4a9c-9ffd-1ac6788d1372",
                        "_blank"
                      );
                    }}
                  >
                    Privacy
                  </p>
                </div>
                <div className="item follow">
                  <p className="item-title">FOLLOW</p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/yanidaltd/",
                        "_blank"
                      );
                    }}
                  >
                    Instagram
                  </p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open(
                        "https://www.tiktok.com/@yanidaltd?lang=en",
                        "_blank"
                      );
                    }}
                  >
                    Tiktok
                  </p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open("https://twitter.com/YanidaLtd", "_blank");
                    }}
                  >
                    Twitter
                  </p>
                  <p
                    className="item-text"
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/profile.php?id=100089673950704",
                        "_blank"
                      );
                    }}
                  >
                    Facebook
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
